import React, {useContext,lazy,Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import './App.css';
import AppShell from './AppShell';
import { AuthProvider, AuthContext} from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';


import FourOFour from './pages/FourOFour';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import defaultLoadingGif from './images/80748-square-loading-animation.gif';
import useWindowDimensions from './util/windowDimensions';
// import axios from 'axios';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Inventory = lazy(() => import('./pages/Inventory'));
const Account = lazy(() => import('./pages/Account'));
const Settings = lazy(() => import('./pages/Settings'));
const Users = lazy(() => import('./pages/Users'));


const AuthenticatedRoute = ({ children,...rest}) => {
  const authContext = useContext(AuthContext);
  return (
    <Route {...rest} render={() => 
      authContext.isAuthenticated() ? (
        <AppShell>
          {children}
        </AppShell>
      ) : (
        <Redirect to="/" />
      )
    } />
  )
}

const AdminRoute = ({ children,...rest}) => {
  const authContext = useContext(AuthContext);
  return (
    <Route {...rest} render={() => 
      authContext.isAuthenticated() && authContext.isAdmin() ? (
        <AppShell>
          {children}
        </AppShell>
      ) : (
        <Redirect to="/" />
      )
    } />
  )
}



const Loading = () => {
  const { height} = useWindowDimensions();
  const top = (height/2)-50;
  return (
    <div className="loadingWrap">
      <img src={defaultLoadingGif} style={{marginTop: top + 'px'}}  />
    </div>
  )
}

const AppRoutes = () => {
  
  return (
    <Suspense fallback={<Loading />} >
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <AuthenticatedRoute path="/dashboard" >
        <Dashboard />
      </AuthenticatedRoute>
      <AdminRoute path="/inventory">
          <Inventory />
      </AdminRoute>
      <AuthenticatedRoute path="/account">
          <Account />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/settings">
          <Settings />
      </AuthenticatedRoute>
      <AdminRoute path="/users">
          <Users />
      </AdminRoute>
      <Route path="*">
        <FourOFour />
      </Route>
    </Switch>
    </Suspense>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>
          <div className="bg-gray-100">
            <AppRoutes />
          </div>
        </FetchProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
